<template>
  <CFooter>
    <div class="ms-auto">
      <span class="me-1 text-muted" target="_blank">Desarrollado por Eddy Pérez - Versión 1.0.0</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
